<template>
  <div>
    <v-container grid-list-md>
      <v-layout wrap>
        <v-flex>
          <v-data-table
            :search="search"
            :loading="loading"
            :headers="headers"
            :items="Questionnaires"
            sort-by="name"
            class="elevation-1 mt-3"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><b>كل الاستبيانات</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-flex>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="بحث"
                    single-line
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-toolbar>
            </template>
            <template v-slot:[`item.externalNavigation`]="{ item }">
              {{ item.externalNavigation.name }}
            </template>
            <template v-slot:[`item.date`]="{ item }">
              {{ createdDate(item.date) }}
            </template>
            <template v-slot:[`item.externalType`]="{ item }">
              {{ item.externalNavigation }}
              <!-- {{ getExternalType(item.externalNavigation.cementPackage) }} -->
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                color="green"
                text-color="white"
                small
                class="mr-2"
                @click="goToQuestionnaire(item)"
              >
                mdi-eye
              </v-icon>
            </template>
            <template v-slot:no-data>
              لا توجد بيانات
              <v-icon x-small @click="getAllQuestionnaires">redo</v-icon>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AllQuestionnaires",
  data() {
    return {
      search: "",
      dialogDelete: false,
      loading: false,
      headers: [
        {
          text: " اسم العميل",
          align: "center",
          sortable: true,
          value: "externalNavigation"
        },
        {
          text: " الخارجية",
          align: "center",
          sortable: false,
          value: "externalType"
        },
        {
          text: "تاريخ الاستبيان",
          align: "center",
          sortable: false,
          value: "date"
        },
        { text: "خيارات", align: "center", value: "actions", sortable: false }
      ],
      Questionnaires: []
    };
  },
  methods: {
    goToQuestionnaire(data) {
      this.$router.push({
        name: "show_questionnaire",
        params: { questionnaireId: data.questionnaire, replyId: data.idreply }
      });
    },
    getExternalType(id) {
      if (id == 1) return "مصنع";
      else if (id == 2) return "مشروع";
      else if (id == 3) return "مقاول";
      else if (id == 4) return "مبسط";
      else if (id == 5) return "موقف";
      else return "ناقل";
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    },
    async getAllQuestionnaires() {
      this.Questionnaires = [];
      this.loading = false;
      await this.ApiService.get("Questionnaire/GetAllQuestionnaires")
        .then(res => {
console.log(res.data.responseData)
          let allQuestionnaires = res.data.responseData;

          allQuestionnaires.forEach(el => {
            if (el.questionnaire.replies.length > 0)
              el.questionnaire.replies.forEach(elm => {
                this.Questionnaires.push(elm);
                console.log(elm)
              });
          });
         
        })
        .catch(() => {});
      this.loading = false;
    }
  },
  created() {
    this.getAllQuestionnaires();
  }
};
</script>
<style lang="scss"></style>
